<!-- 修改密码 -->
<template lang="">
  <div class="login">
    <div class="app-haeder">
      <van-nav-bar
        title="注册账号"
        left-text=""
        left-arrow
        class="navbar"
        @click-left="goBack"
      />
    </div>
    <div class="loginimg">
      <img
        src="https://lesson.iapeap.com/images/EmotionalValue/loginimg.png"
        alt=""
      />
    </div>

    <div class="from">
      <div class="from-title">注册账号</div>
      <div class="headline-fk"></div>

      <van-field
        v-model="invitationCode"
        name="邀请码"
        label="邀请码"
        placeholder="请输入您的邀请码"
        :rules="[{ required: true, message: '请填写完整信息' }]"
      />
      <div class="moren">您收到的邀请码</div>

      <van-field
        v-model="nickName"
        name="昵称"
        label="昵称"
        placeholder="请输入您的昵称"
        :rules="[{ required: true, message: '请填写完整信息' }]"
      />
      <div class="moren">设置一个喜欢的昵称</div>

      <van-field
        v-model="userName"
        name="账号"
        label="账号"
        placeholder="请输入您的账号"
        :rules="[
          { required: true, message: '请填写完整信息' },
          { min: 6, message: '账号长度不能小于6位' },
        ]"
      />
      <div class="moren">将作为您的登录账号(不小于6位)</div>
      <van-field
        v-model="passWord"
        type="password"
        name="密码"
        label="密码"
        placeholder="请输入数字加字母"
        :rules="[
          { required: true, message: '请填写完整信息' },
          { min: 8, message: '密码长度不能小于8位' },
        ]"
      />
      <div class="moren">密码建议：包含8个以上数字 + 字母大小写</div>
      <div class="fromlogin">
        <button class="fromlogin-button" @click="onSubmit">注册</button>
      </div>
    </div>
    <div class="showbox" v-if="showbox">
      <img
        src="https://lesson.iapeap.com/images/EmotionalValue/qxjzcy2.png"
        alt=""
      />
      <div class="content">
        <div>
          欢迎大家来到情绪训练营，很高兴能够和你一起度过一段自我探索的时光。
        </div>
        <div>
          我们很期待大家在练习过程中有所收获，也希望大家在过程中注意信息安全，避免上传和分享个人隐私相关内容。
        </div>
        <div class="btn">
          <button @click="gohome">我知道了</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { userRegister } from "../../api/login";
export default {
  data() {
    return {
      nickName: "",
      userName: "",
      passWord: "",
      invitationCode: "",
      showbox: false,
      value1: 0,
      value2: "a",
      option1: [
        { text: "公司1", value: 0 },
        { text: "公司2", value: 1 },
        { text: "公司3", value: 2 },
      ],
      unitId: window.localStorage.getItem("unitId"), //公司id
      company: "", //公司
    };
  },

  methods: {
    onSubmit() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        message: "正在注册",
        forbidClick: true,
      });
      let data = {
        invitationCode: this.invitationCode,
        nickName: this.nickName,
        userName: this.userName,
        passWord: this.passWord,
      };

      if (this.userName && this.passWord) {
        if (this.userName.length < 6) {
          Toast("账号小于6位数");
        } else {
          userRegister(data).then((res) => {
            Toast.clear();
            console.log(res);
            //注册成功操作
            if (res.msg == "注册成功") {
              Toast.success("注册成功");
              window.localStorage.setItem("userId", res.userId);
              setTimeout(() => {
                this.showbox = !this.showbox;
              }, 1000);
            }
            if (res.msg == "邀请码错误！") {
              Toast.success("邀请码错误！");
              Toast("邀请码错误！");
            }

            if (res.msg == "该用户已存在，请直接登录！") {
              Toast.fail("该用户已存在");
            }
          });
        }
      } else {
        Toast.fail("有选项为空");
      }
    },
    gohome() {
      this.$router.push("/");
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.unitId == "undefined") {
      this.company = "高情商训练营";
    }
    if (this.unitId == "sys1818") {
      this.company = "情绪价值训练营";
    }
    if (this.unitId == "1397") {
      this.company = "高情商训练营";
    }
  },
};
</script>
<style lang="less">
.navbar {
  background-color: #ededed;
}
.van-nav-bar .van-icon {
  color: #464646;
}
.login {
  .loginimg {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 131px;
      height: 94px;
    }
  }

  .from {
    height: 428px;
    box-shadow: 0px 2px 8px 0px rgba(185, 184, 184, 0.5);
    margin: 0 20px;
    box-shadow: 5px #333;
    position: relative;
    bottom: 4px;
    padding: 35px 30px 72px 30px;
    box-sizing: border-box;
  }

  .from-title {
    color: rgba(0, 0, 37, 1);
    font-size: 24px;
    line-height: 33px;
    position: relative;
    z-index: 1;
  }

  .from-title2 {
    color: rgba(0, 0, 37, 1);
    font-size: 24px;
    line-height: 33px;
    margin-top: -4px;
    margin-bottom: 50px;
  }

  .headline-fk {
    width: 64px;
    height: 5px;
    background: rgb(104, 154, 217);
    border-radius: 1px;
    position: relative;
    right: 5px;
    bottom: 9px;
    z-index: 0;
    transform: skew(-25deg);
  }

  .from-button {
    background-color: #fff;
    border: 0;
    color: rgb(114, 172, 244);
  }

  .van-cell {
    padding: 0.1rem 0rem;
    border: 0;
  }

  .van-field__body {
    border-radius: 8px;
    height: 28px;
    border: 1px solid rgba(224, 224, 224, 1);
    height: 28px;
  }

  .van-field__control {
    width: 130px;
  }

  .fromlogin {
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .fromlogin-button {
      background-color: rgba(114, 172, 244, 1);
      border-radius: 100px;
      padding: 5px 73px 6px 73px;
      border: 0;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
    }
  }

  .van-field__label {
    width: 45px;
    line-height: 32px;
  }

  .van-hairline--top-bottom::after,
  .van-hairline-unset--top-bottom::after {
    border-width: 0;
  }

  .van-cell::after {
    border-bottom: 0;
  }
  .moren {
    color: #bbbaba;
    font-size: 12px;
    text-align: right;
  }
  .showbox {
    position: absolute;
    top: 0;
    z-index: 999;
    display: flex; /* 使用 Flexbox 布局 */
    flex-direction: column; /* 设置主轴方向为垂直方向 */
    align-items: center; /* 在交叉轴上居中对齐 */
    justify-content: center; /* 在主轴上居中对齐 */
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    img {
      height: 200px;
      width: 300px;
      border-radius: 10px 10px 0 0;
    }
    .content {
      width: 300px;
      box-sizing: border-box;
      background-color: #fff;
      box-shadow: rgba(185, 184, 184, 0.5);
      border-radius: 0 0 10px 10px;
      color: #000025;
      line-height: 25px;
      text-indent: 20px;
      padding: 0 20px 20px 20px;

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        button {
          border: 0;
          color: #fff;
          background-color: #72acf4;
          width: 164px;
          height: 36px;
          border-radius: 25px;
          box-shadow: rgba(185, 184, 184, 0.5);
        }
      }
    }
  }
}
</style>
